import React from 'react';
import { ProductPreview } from '../product-preview/product-preview';
import { CollectionForm } from '../collection-form/collection-form';
import styles from './app.scss';

export const App: React.FC = () => {
  return (
    <div className={styles.root}>
      <ProductPreview
        product={{
          imageUrl:
            'https://static.wixstatic.com/media/22e53e_01575d792adb43a6a16595bd74a1cc8d~mv2.jpg',
          name: 'Product Name',
        }}
      />
      <CollectionForm />
    </div>
  );
};

import React from 'react';
import { Text, Image } from 'wix-ui-tpa/cssVars';
import { classes } from './product-preview.st.css';

const IMAGE_DIMENSIONS = 120;

export const ProductPreview: React.FC<{ product: { name: string; imageUrl: string } }> = ({
  product,
}) => {
  return (
    <div className={classes.root}>
      <Image
        className={classes.image}
        src={product.imageUrl}
        width={IMAGE_DIMENSIONS}
        height={IMAGE_DIMENSIONS}
      />
      <Text className={classes.title}>{product.name}</Text>
    </div>
  );
};

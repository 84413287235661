import React from 'react';
import { TPAComponentsProvider } from 'wix-ui-tpa/cssVars';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { ApiProvider } from './components/api-provider/api-provider';
import { CollectionWidgetProps } from '../common/types';
import AppWrapper from './components/app-wrapper/app-wrapper';
import { App } from './components/app/app';

const Widget: React.FC<CollectionWidgetProps> = (props) => {
  const { isMobile, isRTL } = useEnvironment();
  // TODO: handle error boundary

  return (
    <TPAComponentsProvider
      // eslint-disable-next-line react-hooks/rules-of-hooks
      value={React.useMemo(() => ({ mobile: isMobile, rtl: isRTL }), [isMobile, isRTL])}
    >
      <ApiProvider {...props}>
        <AppWrapper>
          <App />
        </AppWrapper>
      </ApiProvider>
    </TPAComponentsProvider>
  );
};

export default Widget;

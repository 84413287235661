import React from 'react';
// import classNames from 'classnames';
import styles from '../../styles/collection-styles.scss';

const AppWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <section
      className={styles.collectionWidget}
      // data-hook={classNames(ROOT)}
      tabIndex={-1}
    >
      {children}
    </section>
  );
};

export default AppWrapper;
